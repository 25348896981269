<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { Link } from '@inertiajs/vue3'
import { BlogPost as IBlogPost } from '@interfaces/blog-post.interface'
import BlogPost from '@/Components/UI/BlogPost.vue'

defineProps<{
    posts: Array<IBlogPost>
}>()

const { t } = useI18n()
</script>

<template>
    <section class="pt-16 pb-24 bg-white sm:pb-32">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="sm:flex sm:items-baseline sm:justify-between">
                <h2 class="text-2xl font-bold tracking-tight text-gray-900">{{ t('pages.home.latest-articles.latest-in-journal') }}</h2>
                <Link :href="route('blog.index')" class="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block">
                {{ t('pages.home.latest-articles.discover-more') }}
                <span aria-hidden="true"> &rarr;</span>
                </Link>
            </div>

            <div class="grid grid-cols-1 mx-auto mt-6 gap-y-20 gap-x-8 md:grid-cols-3">
                <BlogPost v-for="post in posts" :key="post.id" :post="post" />
            </div>
        </div>
    </section>
</template>
